import { useState, useEffect } from "react"
import useRefreshToken from '../hooks/useRefreshToken';
import { NavLink } from "react-router-dom"
import { ReactComponent as MenuButton } from '../assets/images/dc_menu.svg'
import anime from 'animejs/lib/anime.es.js';
import Cookies from 'js-cookie'

const Navigation = () => {

	const [menuOpen, setMenuOpen] = useState(false)
	const refresh = useRefreshToken();


	useEffect(() => {
// 		let canRefresh = Cookies.get("iyh-can-refresh")
//
// 		if (canRefresh && canRefresh === "true"){
// 			console.log('Trying to log back in...')
// 			refresh()
// 		}

		anime({
			targets: 'nav.main a',
			right: -20,
			opacity: 0,
		})
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		document.body.classList.toggle('menu__active', menuOpen);
	},[menuOpen])

	const handleToggle = () => {

		setMenuOpen(prev => !prev)
		if (!menuOpen){
			anime({
				targets: 'nav.main a',
				right: 0,
				opacity: 1,
				delay: anime.stagger(100)
			})
		} else {
			anime({
				targets: 'nav.main a',
				right: -20,
				opacity: 0,
			})
		}
	}
	return (
		<div id="navigationWrapper">

			<button className={`menu__button ${menuOpen ? " active" : ""}`} onClick={handleToggle}>{menuOpen ? 'x' : <MenuButton />}</button>

			<section className={`menu__overlay ${menuOpen ? 'enabled' : ''}`}>
				<nav className="main">
					<NavLink onClick={handleToggle} className="menu__link" to="/">Home</NavLink>
					<NavLink onClick={handleToggle} className="menu__link" to="/cameras">Cameras</NavLink>
					<NavLink onClick={handleToggle} className="menu__link" to="/alerts">Alerts</NavLink>
					<NavLink onClick={handleToggle} className="menu__link" to="/notifications">Notifications</NavLink>
					<NavLink onClick={handleToggle} className="menu__link" to="/closure-history">Closure History</NavLink>
					<NavLink onClick={handleToggle} className="menu__link" to="/other-passes-and-routes">Other Routes</NavLink>
					<NavLink onClick={handleToggle} className="menu__link" to="/news-and-updates">News & Updates</NavLink>
					<NavLink onClick={handleToggle} className="menu__link" to="/about-us">About Us</NavLink>
					<NavLink onClick={handleToggle} className="menu__link" to="/support">Support</NavLink>
				</nav>
			</section>

		</div>
	)
}

export default Navigation