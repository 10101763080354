import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import HeaderMini from '../components/HeaderMini.js';
import MMHeader from '../components/MMHeader';
import Footer from '../components/Footer';
import Maintenance from '../components/Maintenance';
import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';


const Layout = () => {

	const [loading, setLoading] = useState(false);
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();

	const [data, setData] = useState()

	const [maintenanceMode, setMaintenanceMode]= useState(true)
	const [notifications, setNotifications] = useState()
	const [passName, setPassName] = useState(process.env.REACT_APP_PASS_NAME)

	const [isIframe, setIsIframe] = useState(false)

	const [error, setError] = useState()

	const auth = useAuth();

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getData = async () => {
			if (window.self !== window.top){
				// Log Address
				const reportIframe = await axiosPrivate.post('report-iframe',
				JSON.stringify({ip_address: 'na', url: document.referrer }),
				{
					signal: controller.signal
				});

				// Redirect
				// navigate('/widget', { state: { from: location }, replace: true });

				setIsIframe(true)
			}


			try {
				const response = await axiosPrivate.get('data/pass/' + process.env.REACT_APP_PASS, {
					signal: controller.signal
				});
				setMaintenanceMode(response.data.pass_data.maintenance.maintenance_mode_enabled)
				setData(response.data)
				setNotifications(response.data.pass_data.notifications)
				setLoading(false)
			} catch(err) {
				setError(err.message)
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getData();

		return () => controller.abort();
		// eslint-disable-next-line
	}, [])

	const refreshData = async () => {
		const controller = new AbortController();
		setLoading(true);
		const dismiss = () =>  toast.dismiss(updateToast.current);
		const updateToast = toast.loading("Updating Conditions");

		try {
			const response = await axiosPrivate.get('data/pass/' + process.env.REACT_APP_PASS, {
				signal: controller.signal
			});
			setMaintenanceMode(response.data.pass_data.maintenance.maintenance_mode_enabled)
			setData(response.data)
			setNotifications(response.data.pass_data.notifications)
			setLoading(false)
			toast.update(updateToast, { render: 'Conditions Updated', type: 'success', isLoading: false, autoClose: 5000});

		} catch(err) {
			setError(err.message)
			console.log(err.message);
			toast.update(updateToast, { render: 'Failed to update condition', type: 'error', isLoading: false, autoClose: 5000});

			!auth && navigate('/login', { state: { from: location }, replace: true });
		}
		return () => controller.abort();

	}

	return (
		<main className="App">
			{maintenanceMode ?
				<>
					<MMHeader />
					<Maintenance loading={loading} passName={passName}/>
					<Footer />
				</>
			:
				isIframe ?
				<>
					<HeaderMini data={data} setData={setData} loading={loading} />
					<section className="contentWrapper" id="home">
						<section className='primary'>
							<h3>Unauthorized Feed!</h3>
							<p>You are attempting to use this site in an iframe. Want to display our data? Please <a href={`${origin}/support`} target='_blank'>Contact Us</a> to get a solution that works for your needs.</p>
						</section>
					</section>
					<Footer data={data} />
				</>
				:
				<>
					<Header notifications={notifications} data={data} setData={setData} refreshData={refreshData} loading={loading} />
					<Outlet context={[data, setData]} />
					<Footer data={data} />
				</>
			}

			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>

		</main>
	)
}

export default Layout