import { useOutletContext, useLocation, useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import TabPanel from '../components/TabPanel'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { BsFillHandThumbsDownFill } from 'react-icons/bs'
import { BsFillHandThumbsUpFill } from 'react-icons/bs'
import UserReportPopup from '../components/UserReportPopup'
import { useMediaQuery } from 'react-responsive'
import { SuperSEO } from "react-super-seo";

const dcColors = createTheme({
  palette: {
	primary: {
	  light: '#007e9b',
	  main: '#007e9b',
	  contrastText: '#007e9b',
	},
	contrastThreshold: 3,
	tonalOffset: 0,
  },
});

const Types = {

}

const Alerts = () => {

	const { auth } = useAuth()
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate()

	const [settings, setSettings] = useState(auth?.settings)

	const [userSettings, setUserSettings] = useState({
		cdot: 1,
		user: 1,
		closures: 1,
		conditions: 1,
		confirm_threshold: 0,
		clear_threshold: 1
	})

	const isMobileTabs = useMediaQuery({
		query: '(max-width: 600px)'
	})

	useEffect(() => {
		if (auth?.user){
			setUserSettings({
				cdot: JSON.parse(settings?.user_settings).cdot_feed,
				user: JSON.parse(settings?.user_settings).user_feed,
				closures: JSON.parse(settings?.user_settings).user_feed_config.closures,
				conditions: JSON.parse(settings?.user_settings).user_feed_config.conditions,
				confirm_threshold: JSON.parse(settings?.user_settings).user_feed_config.confirm_threshold,
				clear_threshold: JSON.parse(settings?.user_settings).user_feed_config.clear_threshold,
			})
		}
	}, [auth])

	const [data, setData] = useOutletContext()
	const [incidents, setIncidents] = useState([])
	const [events, setEvents] = useState([])
	const [conditions, setConditions] = useState()
	const [weather, setWeather] = useState()
	const [cameras, setCameras] = useState()
	const [history, setHistory] = useState()
	const [status, setStatus] = useState()
	const [currentConditions, setCurrentConditions] = useState([])
	const [userReports, setUserReports] = useState([])
	const [popup, setPopup] = useState()

	const [userConditions, setUserConditions] = useState()
	const [userTraffic, setUserTraffic] = useState()
	const [userConstruction, setUserConstruction] = useState()
	const [userClosures, setUserClosures] = useState()

	const location = useLocation()
	const { tabIndex } = location?.state || 0

	const [tab, setTab] = useState(tabIndex || 0)

	const [openConfirm, setOpenConfirm] = useState(false)
	const [openOppose, setOpenOppose] = useState(false)
	const [openDetails, setOpenDetails] = useState(false)
	const [openThanks, setOpenThanks] = useState(false)

	const [confirmObj, setConfirmObj] = useState([])
	const [opposeObj, setOpposeObj] = useState([])
	const [detailsObj, setDetailsObj] = useState([])

	const [openReport, setOpenReport] = useState(false)

	const handleConfirm = async (e) => {
		const dismiss = () =>  toast.dismiss(confirmToast.current);
		const confirmToast = toast.loading("Confirming Alert");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('confirm-report/' + process.env.REACT_APP_PASS + '/' + confirmObj.id,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(confirmToast, { render: 'Alert confirmed', type: 'success', isLoading: false, autoClose: 5000});

			// Update State
			const updatedReports = response.data.user_reports

			// Update data context
			setData({...data, pass_data: { ...data.pass_data, user_reports: updatedReports}})


			// On success
			setOpenConfirm(false)

		} catch (err) {
			if (!err?.response) {
				toast.update(confirmToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(confirmToast, { render: 'Failed to confirm alert', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleCloseThanks = () => {
		setOpenThanks(false)
	}

	const handleOpenReport = () => {

	}

	const handleOpenConfirm = (alert) => {
		setConfirmObj(alert)
		setOpenConfirm(true)
	}

	const handleCloseConfirm = () => {
		setOpenConfirm(false)
	}

	const handleOppose = async (e) => {
		const dismiss = () =>  toast.dismiss(opposeToast.current);
		const opposeToast = toast.loading("Clearing Alert");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('oppose-report/' + process.env.REACT_APP_PASS + '/' + opposeObj.id,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(opposeToast, { render: 'Alert Cleared', type: 'success', isLoading: false, autoClose: 5000});

			// Update State
			const updatedReports = response.data.user_reports

			// Update data context
			setData({...data, pass_data: { ...data.pass_data, user_reports: updatedReports}})

			// On success
			setOpenOppose(false)

		} catch (err) {
			if (!err?.response) {
				toast.update(opposeToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(opposeToast, { render: 'Failed to clear alert', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleOpenOppose = (alert) => {
		setOpposeObj(alert)
		setOpenOppose(true)
	}

	const handleCloseOppose = () => {
		setOpenOppose(false)
	}

	const handleConditionDetails = (alert) => {
		setOpenDetails(true)
		setDetailsObj(alert)
	}

	const handleOpenDetails = () => {
		setOpenDetails(true)
	}

	const handleCloseDetails = () => {
		setOpenDetails(false)
	}

	const handleLoginSignup = () => {
		setOpenDetails(false)
		navigate('/login')
	}

	useEffect(() => {
		setIncidents(data.pass_data.incidents)
		setEvents(data.pass_data.events)
		setConditions(data.pass_data.conditions)
		setWeather(data.pass_data.weather)
		setStatus(data.pass_data.status)
		setPopup(data.popup)
		setUserConditions(data.pass_data.user_reports.user_conditions)
		// setUserTraffic(data.pass_data.user_reports.user_traffic)
		setUserClosures(data.pass_data.user_reports.user_closures)
		// setUserConstruction(data.pass_data.user_reports.user_construction)

		const userTraffic = data.pass_data.user_reports.user_traffic
		const userClosures = data.pass_data.user_reports.user_closures
		const userConstruction = data.pass_data.user_reports.user_construction
		setUserReports([...userClosures, ...userTraffic, ...userConstruction])

		const conditionsArray = data.pass_data.conditions?.road_conditions.split(',')
		setCurrentConditions(conditionsArray)

	}, [data])

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	const formatTimestamp = (ts) => {
		if (ts){
			const options = { month: "long", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" }
			return new Date(ts).toLocaleDateString(undefined, options)
		} else {
			return "N/A"
		}
	}

	const getClass = (type) => {
		type = type.toLowerCase()

		if (type.includes('chain')){ return 'chainLaw'}
		if (type.includes('traction')){ return 'tractionLaw'}
		if (type.includes('maintenance')){ return 'roadMaintenance'}
		if (type.includes('construction')){ return 'construction'}
		if (type.includes('closed')){ return 'closed'}
		if (type.includes('closure')){ return 'closed'}
		if (type.includes('avalanche')){ return 'avalanche'}

		return "unknown"
	}

	const getUserClass = (type) => {
		type = type.toLowerCase()
		return type.replace(' ', '-')
	}

	function a11yProps(index) {
	  return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	  };
	}

	const handleTabChange = async (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	}

	return (
		<section className="contentWrapper" id="alerts">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_ALERTS_TITLE}
			  description={process.env.REACT_APP_SEO_ALERTS_DESCRIPTION}
			/>

			<section className="primary">
				<article>
					<ThemeProvider theme={dcColors}>
						<Tabs
							value={tab}
							onChange={handleTabChange}
							orientation={isMobileTabs ? "vertical" : "horizontal"}
					  	>
							<Tab icon={<Badge badgeContent={incidents?.length} color="primary" showZero="true" overlap="circular"></Badge>} iconPosition="start" label="CDOT Incidents" {...a11yProps(0)} />
							<Tab icon={<Badge badgeContent={events?.length} color="primary" showZero="true" overlap="circular"></Badge>} iconPosition="start" label="CDOT Events" {...a11yProps(1)} />
							{ userSettings.user ?
								<Tab icon={<Badge badgeContent={userReports?.length} color="primary" showZero="true" overlap="circular"></Badge>} iconPosition="start" label="User Reports" {...a11yProps(2)} />
								:
								null
							}
						</Tabs>
					</ThemeProvider>

					<TabPanel value={tab} index={0}>
						<>
							<h2>Incidents ({incidents.length})</h2>
							{ incidents.length ?
								<ul className="incidents">
								{incidents?.map((incident, i) =>
									<li className="incident" key={i}>
										<h4 className={`${getClass(incident?.incident_type)}`}>{incident?.incident_type}</h4>
										<div className="content">
											<div className="description">{incident?.incident_description}</div>
											<div className="impact"><strong>Impact:</strong> {incident?.additional_impact_string}</div>
										</div>
									</li>
								)}
								</ul>
								:
								<p>No incidents at this time</p>
							}
						</>
					</TabPanel>

					<TabPanel value={tab} index={1}>
						<>
							<h2>Events ({events.length})</h2>
							{ events.length ?
								<ul className="events">
								{events?.map((event, i) =>
									<li className="event" key={i}>
										<h4 className={`${getClass(event?.planned_type)}`}>{event?.planned_type}</h4>
										<div className="content">
											<div className="description">{event?.planned_description}</div>
											<div className="impact"><strong>Impact:</strong> {event?.additional_impact_string}</div>
											<div className="start"><strong>Scheduled Start:</strong> {formatDate(event?.schedule_start_time)}</div>
											<div className="end"><strong>Scheduled End:</strong> {formatDate(event?.schedule_end_time)}</div>
										</div>
									</li>
								)}
								</ul>
								:
								<p>No events at this time</p>
							}
						</>
					</TabPanel>

					{ userSettings.user ?
						<TabPanel value={tab} index={2}>
							<>
								<h2>User Reports ({userReports?.length})</h2>
								{userReports?.length > 0 ? <p className='reportNote'>User report count may be different than what you see.<br />This is because your threshold is less than or equal to the times the report has been opposed.</p> : ''}
								<button className="marginBottom" onClick={(e) => setOpenReport(true)}>Add your own report</button>
								{userReports?.length ?
									<ul className="userAlerts">
										{userReports?.map((report, i) =>
											report.opposed < userSettings.clear_threshold && report.confirmed >= userSettings.confirm_threshold ?
												<li key={i} className="reports">
													{/* <span className={`icon ${getUserClass(report.report)}`}></span> */}
													<h4 className={`${getUserClass(report.report)}`}>{report.report} {report.report_category === 'traffic' && 'Traffic'} {report.report_category === 'construction' && 'Construction'}
														{auth?.user &&
															<div className="confirmation">
																<div className="down" onClick={(e) => handleOpenOppose(report)}><BsFillHandThumbsDownFill /></div>
																<div className="up" onClick={(e) => handleOpenConfirm(report)}><BsFillHandThumbsUpFill /></div>
															</div>
														}
													</h4>
													<div className="content">
														<p><strong>Reported at:</strong> {formatTimestamp(report.created_at)}</p>
														<p><strong>Last updated at:</strong> {formatTimestamp(report.updated_at)}</p>
														<p><strong>Confirmed by:</strong> {report.confirmed} {report.confirmed !== 1 ? 'users' : 'user'}</p>
														<p><strong>Cleared by:</strong> {report.opposed} {report.opposed !== 1 ? 'users' : 'user'}</p>
													</div>
												</li>
												:
												null
										)}
									</ul>
									:
									<p>No user reports at this time</p>
								}
							</>
						</TabPanel>
						:
						null
					}
				</article>
			</section>
			<section className="secondary">
			</section>

			<Dialog
				open={openConfirm}
				onClose={handleCloseConfirm}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="confirm"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Confirm User Report`}
				</DialogTitle>
				<DialogContent>
					<>
						<span><strong>User(s) Reported:</strong> {confirmObj.report}</span><br />
						<span><strong>Reported at:</strong> {formatTimestamp(confirmObj.created_at)}</span><br />
						<span><strong>Confirmed Count:</strong> {confirmObj.confirmed}</span><br />
						<span><strong>Last confirmed at:</strong> {formatTimestamp(confirmObj.updated_at)}</span>
					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseConfirm}>Cancel</button>
					<button className="confirm" onClick={handleConfirm}>Confirm</button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openOppose}
				onClose={handleCloseOppose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="oppose"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Clear User Report`}
				</DialogTitle>
				<DialogContent>
					<>
						<p className="clearNote">Is user report '{opposeObj.report}' still present? If it isn't click clear and it will be removed from our report.</p><br />
						<span><strong>User(s) Reported:</strong> {opposeObj.report}</span><br />
						<span><strong>Cleared Count:</strong> {confirmObj.opposed}</span><br />
						<span><strong>Originally Reported at:</strong> {formatTimestamp(opposeObj.created_at)}</span><br />
						<span><strong>Last updated at:</strong> {formatTimestamp(opposeObj.updated_at)}</span>
					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseOppose}>Cancel</button>
					<button className="confirm" onClick={handleOppose}>Clear</button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openDetails}
				onClose={handleCloseDetails}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="confirm"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`User Report Details`}
				</DialogTitle>
				<DialogContent>
					<>
						<span><strong>{detailsObj.confirmed > 1 ? 'Users' : 'User'} Reported:</strong> {detailsObj.report}</span><br />
						<span><strong>Reported at:</strong> {formatTimestamp(detailsObj.created_at)}</span><br />
						<span><strong>Confirmed Count:</strong> {detailsObj.confirmed}</span><br />
						<span><strong>Cleared Count:</strong> {detailsObj.opposed}</span><br />
						<span><strong>Last confirmed at:</strong> {formatTimestamp(detailsObj.updated_at)}</span><br />
						{!auth?.user &&
							<p className="reportNote">Help keep others informed of latest conditions. Login or signup now to add your own first hand reports.</p>
						}

					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseDetails}>Close</button>
					{ !auth?.user &&
						<button className="confirm" onClick={handleLoginSignup}>Signup/Login</button>
					}
				</DialogActions>
			</Dialog>

			<Dialog
				open={openThanks}
				onClose={handleCloseThanks}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="thanks"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Thanks for clearing a old report!`}
				</DialogTitle>
				<DialogContent>
					<>
						<p>Thanks for clearing an old report. If CDOT's data is not providing enough information for current conditions would you like to add your own report now?</p>

					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseThanks}>Close</button>
					<button className="confirm" onClick={handleOpenReport}>Report</button>
				</DialogActions>
			</Dialog>

			<UserReportPopup openReport={openReport} setOpenReport={setOpenReport} data={data} setData={setData} />

		</section>
	)
}

export default Alerts