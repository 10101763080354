const scale = {
	low: {
		travel_advice: 'Generally safe avalanche conditions. Watch for unstable snow on isolated terrain features.',
		likelihood_of_avalanche: 'Natural and human-triggered avalanches unlikely.'
	},
	moderate: {
		travel_advice: 'Heightened avalanche conditions on specific terrain features. Evaluate snow and terrain carefully; identify features of concern.', likelihood_of_avalanche: 'Natural avalanches unlikely; human-triggered avalanches possible.',
	},
	considerable: {
		travel_advice: 'Dangerous avalanche conditions. Careful snowpack evaluation, cautious route-finding and conservative decision-making essential.',
		likelihood_of_avalanche: 'Natural avalanches possible; human-triggered avalanches likely.',
	},
	high: {
		travel_advice: 'Very dangerous avalanche conditions. Travel in avalanche terrain not recommended.',
		likelihood_of_avalanche: 'Natural avalanches likely; human-triggered avalanches very likely.',
	},
	extreme: {
		travel_advice: 'Extraordinarily dangerous avalanche conditions. Avoid all avalanche terrain.',
		likelihood_of_avalanche: 'Natural and human-triggered avalanches certain.',
	}
};

const AvyScale = ({status}) => {
	return (
		<div class='details'>
			<h4>{status}</h4>
			<div>{scale[status]['travel_advice']}</div>
			<div>{scale[status]['likelihood_of_avalanche']}</div>
		</div>
	)
}

export default AvyScale