import { WiDaySunny } from "react-icons/wi";
import { WiNightClear } from "react-icons/wi";
import { WiDayRain } from "react-icons/wi";
import { WiRaindrop } from "react-icons/wi";
import { WiThunderstorm } from "react-icons/wi";
import { WiSnow } from "react-icons/wi";
import { WiCloud } from "react-icons/wi";
import { WiCloudy } from "react-icons/wi";
import { WiDayCloudy } from "react-icons/wi";
import { WiNightCloudy } from "react-icons/wi";
import { WiNightPartlyCloudy } from "react-icons/wi";
import { WiNightAltCloudy } from "react-icons/wi";
import { WiFog } from "react-icons/wi";

const icons = {
	"11d" : <WiThunderstorm />,
	"09d" : <WiRaindrop />,
	"10d" : <WiDayRain />,
	"13d" : <WiSnow />,
	"13n" : <WiSnow />,
	"50d" : <WiFog />,
	"01d" : <WiDaySunny />,
	"01n" : <WiNightClear />,
	"02d" : <WiDayCloudy />,
	"02n" : <WiNightCloudy />,
	"03d" : <WiDayCloudy />,
	"03n" : <WiNightCloudy />,
	"04d" : <WiCloudy />,
	"04n" : <WiCloudy />,
}

const WeatherIcons = ({id}) => {
	return (
		<div id="icon">
			{icons[id] || "na"}
		</div>
	)
}

export default WeatherIcons