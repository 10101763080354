const Widget = () => {

	const origin = document.location.origin

	return (

		<section className='primary'>
			<h3>Unauthorized Feed!</h3>
			<p>You are attempting to use this site in an iframe. Want to display our data? Please <a href={`${origin}/support`} target='_blank'>Contact Us</a> to get a solution that works for your needs.</p>
		</section>
	)
}

export default Widget