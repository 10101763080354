import { LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useEffect } from 'react';


const DayChart = ({day}) => {

	// create new array from day data
	const data = [
	  {
		Time: 'Morning',
		Temperature: day?.temp_morn,
	  },
	  {
		Time: 'Day',
		Temperature: day?.temp_day,
	  },
	  {
		Time: 'Evening',
		Temperature: day?.temp_eve,
	  },
	  {
		Time: 'Night',
		Temperature: day?.temp_night,
	  },
	];

	return (
		<div className='chart'>
			<LineChart
			  width={400}
			  height={200}
			  data={data}
			  margin={{
				top: 5,
				right: 30,
				left: 20,
				bottom: 5,
			  }}
			>
			  <CartesianGrid strokeDasharray="3 3" />
			  <XAxis dataKey="Time" />
			  <YAxis />
			  <Tooltip />
			  <Legend />
			  <Line type="monotone" dataKey="Temperature" stroke="#007e9b" activeDot={{ r: 8 }} unit="ºF "/>
			</LineChart>
		</div>
	)
}

export default DayChart