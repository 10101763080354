import Navigation from './Navigation'
import PassHeading from './PassHeading'

const HeaderMini = ({ loading, notifications, refreshData, data }) => {
	return (
		<header>
			<main>
				<PassHeading refreshData={refreshData} loading={loading} />
			</main>
		</header>
	)
}

export default HeaderMini