import { useOutletContext } from "react-router-dom";
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { ReactComponent as Na } from '../assets/images/dc_na.svg'
import { ReactComponent as Dry } from '../assets/images/dc_dry.svg'
import { ReactComponent as Sun } from '../assets/images/dc_sun.svg'
import { ReactComponent as Wind } from '../assets/images/dc_wind.svg'
import { ReactComponent as Wet } from '../assets/images/dc_wet.svg'
import { ReactComponent as Rain } from '../assets/images/dc_rain.svg'
import { ReactComponent as LightRain } from '../assets/images/dc_light-rain.svg'
import { ReactComponent as HeavyRain } from '../assets/images/dc_heavy-rain.svg'
import { ReactComponent as Thunderstorms } from '../assets/images/dc_thunderstorms.svg'
import { ReactComponent as Mixed } from '../assets/images/dc_mixed.svg'
import { ReactComponent as Snow } from '../assets/images/dc_snow.svg'
import { ReactComponent as BlowingSnow } from '../assets/images/dc_blowing-snow.svg'
import { ReactComponent as SnowPacked } from '../assets/images/dc_snow-packed.svg'
import { ReactComponent as Chains } from '../assets/images/dc_chains.svg'
import { ReactComponent as Slick } from '../assets/images/dc_slick.svg'
import { ReactComponent as Fog } from '../assets/images/dc_fog.svg'
import { ReactComponent as Visibility } from '../assets/images/dc_poor-visibility.svg'
import { ReactComponent as Slushy } from '../assets/images/dc_slushy.svg'
import { ReactComponent as Hail } from '../assets/images/dc_hail.svg'
import { ReactComponent as Unplowed } from '../assets/images/dc_unplowed.svg'
import { ReactComponent as Hazard } from '../assets/images/dc_hazard.svg'
import { BsFillHandThumbsDownFill } from 'react-icons/bs'
import { BsFillHandThumbsUpFill } from 'react-icons/bs'
import { MdAdd } from 'react-icons/md'
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TabPanel from '../components/TabPanel'
import UserReportPopup from '../components/UserReportPopup'
import { useMediaQuery } from 'react-responsive'
import { SuperSEO } from "react-super-seo";
import Cookies from 'js-cookie'

const Icons = {
	default: <Na />,
	na: <Na />,
	dry: <Dry />,
	sun: <Sun />,
	wind: <Wind />,
	windy: <Wind />,
	highwind: <Wind />,
	wet: <Wet />,
	wetspots: <Wet />,
	wetinareas: <Wet />,
	rain: <Rain />,
	raining: <Rain />,
	lightrain: <LightRain />,
	scatteredshowers: <LightRain />,
	heavyrain: <HeavyRain />,
	thunderstorms: <Thunderstorms />,
	mixed: <Mixed />,
	snow: <Snow />,
	snowing: <Snow />,
	blowingsnow: <BlowingSnow />,
	snowpacked: <SnowPacked />,
	snowpackedspots: <SnowPacked />,
	snowpackedicyspots: <Slick />,
	chains: <Chains />,
	slick: <Slick />,
	icy: <Slick />,
	icyspots: <Slick />,
	fog: <Fog />,
	unplowed: <Unplowed />,
	hail: <Hail />,
	slushy: <Slushy />,
	poorvisibility: <Visibility />,
	adverseconditions: <Hazard />,
}

const dcColors = createTheme({
  palette: {
	primary: {
	  light: '#007e9b',
	  main: '#007e9b',
	  contrastText: '#007e9b',
	},
	contrastThreshold: 3,
	tonalOffset: 0,
  },
});

const Home = () => {

	const { auth } = useAuth()
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate()

	const [settings, setSettings] = useState(auth?.settings)

	const [userSettings, setUserSettings] = useState({
		cdot: 1,
		user: 1,
		closures: 1,
		conditions: 1,
		confirm_threshold: 0,
		clear_threshold: 1
	})

	const isMobileTabs = useMediaQuery({
		query: '(max-width: 600px)'
	})

	const isMobile = useMediaQuery({
		query: '(max-width: 700px)'
	})

	const [openEntryPopup, setOpenEntryPopup] = useState(false)

	const handleCloseEntryPopup = () => {
		setOpenEntryPopup(false)
	}

	const handleIgnoreEntryPopup = () => {
		Cookies.remove('ignore-notifications')
		Cookies.set('ignore-notifications', 'true', {expires: 1})
		setOpenEntryPopup(false)
	}

	useEffect(() => {
		if (auth?.user && settings){
			setUserSettings({
				cdot: JSON.parse(settings?.user_settings).cdot_feed,
				user: JSON.parse(settings?.user_settings).user_feed,
				closures: JSON.parse(settings?.user_settings).user_feed_config.closures,
				conditions: JSON.parse(settings?.user_settings).user_feed_config.conditions,
				confirm_threshold: JSON.parse(settings?.user_settings).user_feed_config.confirm_threshold,
				clear_threshold: JSON.parse(settings?.user_settings).user_feed_config.clear_threshold
			})
		}
	}, [auth])

	const [openReport, setOpenReport] = useState(false)

	const [data, setData] = useOutletContext()
	const [incidents, setIncidents] = useState([])
	const [events, setEvents] = useState([])
	const [conditions, setConditions] = useState()
	const [weather, setWeather] = useState()
	const [cameras, setCameras] = useState()
	const [history, setHistory] = useState()
	const [status, setStatus] = useState()
	const [currentConditions, setCurrentConditions] = useState([])
	const [userReports, setUserReports] = useState([])
	const [popup, setPopup] = useState()

	const [userConditions, setUserConditions] = useState()
	const [userTraffic, setUserTraffic] = useState()
	const [userConstruction, setUserConstruction] = useState()
	const [userClosures, setUserClosures] = useState()

	const [openConfirm, setOpenConfirm] = useState(false)
	const [openOppose, setOpenOppose] = useState(false)
	const [openDetails, setOpenDetails] = useState(false)
	const [openThanks, setOpenThanks] = useState(false)

	const [confirmObj, setConfirmObj] = useState([])
	const [opposeObj, setOpposeObj] = useState([])
	const [detailsObj, setDetailsObj] = useState([])

	const [complexStatus, setComplexStatus] = useState()
	const [userComplexStatus, setUserComplexStatus] = useState()
	const [isComplexStatus, setIsComplexStatus] = useState(false)

	const [tab, setTab] = useState(0)

	function a11yProps(index) {
	  return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	  };
	}

	const handleTabChange = async (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	}

	const handleConfirmConditions = async (e) => {
		const dismiss = () =>  toast.dismiss(confirmToast.current);
		const confirmToast = toast.loading("Confirming Condition");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('confirm-conditions/' + process.env.REACT_APP_PASS + '/' + confirmObj.id,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(confirmToast, { render: 'Condition confirmed', type: 'success', isLoading: false, autoClose: 5000});

			// Updated Conditions
			const updatedConditions = response.data.conditions

			// Update data context
			setData({...data, pass_data: { ...data.pass_data, user_reports: { ...data.pass_data.user_reports, user_conditions: updatedConditions }}})

			// On success
			setOpenConfirm(false)

		} catch (err) {
			if (!err?.response) {
				toast.update(confirmToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(confirmToast, { render: 'Failed to confirm condition', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleCloseThanks = () => {
		setOpenThanks(false)
	}

	const handleOpenReport = () => {

	}

	const handleOpenConfirm = (condition) => {
		setConfirmObj(condition)
		setOpenConfirm(true)
	}

	const handleCloseConfirm = () => {
		setOpenConfirm(false)
	}

	const handleOpposeConditions = async (e) => {
		const dismiss = () =>  toast.dismiss(opposeToast.current);
		const opposeToast = toast.loading("Clearing Condition");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('oppose-conditions/' + process.env.REACT_APP_PASS + '/' + opposeObj.id,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(opposeToast, { render: 'Condition Cleared', type: 'success', isLoading: false, autoClose: 5000});

			// Update State
			// Updated Conditions
			const updatedConditions = response.data.conditions

			// Update data context
			setData({...data, pass_data: { ...data.pass_data, user_reports: { ...data.pass_data.user_reports, user_conditions: updatedConditions }}})

			// setUserConditions(userConditions.filter((condition) => condition.id !== opposeObj.id))

			// On success
			setOpenOppose(false)

		} catch (err) {
			if (!err?.response) {
				toast.update(opposeToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(opposeToast, { render: 'Failed to clear condition', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleOpenOppose = (condition) => {
		setOpposeObj(condition)
		setOpenOppose(true)
	}

	const handleCloseOppose = () => {
		setOpenOppose(false)
	}

	const handleConditionDetails = (condition) => {
		setOpenDetails(true)
		setDetailsObj(condition)
	}

	const handleOpenDetails = () => {
		setOpenDetails(true)
	}

	const handleCloseDetails = () => {
		setOpenDetails(false)
	}

	const handleLoginSignup = () => {
		setOpenDetails(false)
		navigate('/login')
	}

	useEffect(() => {
		setIncidents(data.pass_data.incidents)
		setEvents(data.pass_data.events)
		setConditions(data.pass_data.conditions)
		setWeather(data.pass_data.weather)
		setStatus(data.pass_data.status)
		setPopup(data.popup)
		setUserConditions(data.pass_data.user_reports.user_conditions)
		// setUserTraffic(data.pass_data.user_reports.user_traffic)
		setUserClosures(data.pass_data.user_reports.user_closures)
		// setUserConstruction(data.pass_data.user_reports.user_construction)

		const userTraffic = data.pass_data.user_reports.user_traffic
		const userClosures = data.pass_data.user_reports.user_closures
		const userConstruction = data.pass_data.user_reports.user_construction
		setUserReports([...userClosures, ...userTraffic, ...userConstruction])

		const conditionsArray = data.pass_data.conditions?.road_conditions.split(',').map((item)=>item.trim());
		const setConditionsData = new Set(conditionsArray)
		setCurrentConditions(Array.from(setConditionsData))

		if (data?.pass_info?.pass === "vail" || data?.pass_info?.pass === "tunnels" || data?.pass_info?.pass === "glenwood-canyon"){
			setIsComplexStatus(true)

			let cdotstatus

			if (data.pass_data.status.eb_status !== data.pass_data.status.wb_status){
				if (data.pass_data.status.eb_status === 0){
					cdotstatus = "EB Closed"
				}
				if (data.pass_data.status.wb_status === 0){
					cdotstatus = "WB Closed"
				}
			} else {
				// setComplexStatus(data.pass_data.status.eb_status ? "Open" : "Closed")
				cdotstatus = data.pass_data.status.eb_status ? "Open" : "Closed"
			}

			// Get user reports
			if (userClosures?.length > 0){
				if (cdotstatus === "Open"){
					if (JSON.stringify(userClosures).includes("wb closed") && JSON.stringify(userClosures).includes("eb closed")){
						setComplexStatus("Closed")
					} else if (JSON.stringify(userClosures).includes("wb closed")){
						setComplexStatus("WB Closed")
					} else if (JSON.stringify(userClosures).includes("eb closed")){
						setComplexStatus("EB Closed")
					} else {
						setComplexStatus("Open")
					}
				} else if (cdotstatus === "Closed") {
					if (JSON.stringify(userClosures).includes("wb open")){
						setComplexStatus("Open")
					} else if (JSON.stringify(userClosures).includes("wb open")){
						setComplexStatus("WB Open")
					} else if (JSON.stringify(userClosures).includes("eb open")){
						setComplexStatus("EB Open")
					} else {
						setComplexStatus("Closed")
					}
				} else if (cdotstatus === "EB Closed") {
					if (JSON.stringify(userClosures).includes("eb open")){
						setComplexStatus("Open")
					} else if (JSON.stringify(userClosures).includes("wb closed")){
						setComplexStatus("Closed")
					} else {
						setComplexStatus("EB Closed")
					}
				} else if (cdotstatus === "WB Closed") {
					if (JSON.stringify(userClosures).includes("wb open")){
						setComplexStatus("Open")
					} else if (JSON.stringify(userClosures).includes("eb closed")){
						setComplexStatus("Closed")
					} else {
						setComplexStatus("WB Closed")
					}
				} else {
					setComplexStatus(cdotstatus)
				}
			} else {
				setComplexStatus(cdotstatus)
			}
		}

	}, [data])

	const getClass = (type) => {
		type = type.toLowerCase()

		if (type.includes('chain')){ return 'chainLaw'}
		if (type.includes('traction')){ return 'tractionLaw'}
		if (type.includes('maintenance')){ return 'roadMaintenance'}
		if (type.includes('construction')){ return 'construction'}
		if (type.includes('closed')){ return 'closed'}
		if (type.includes('closure')){ return 'closed'}
		if (type.includes('avalanche')){ return 'avalanche'}

		return "unknown"
	}

	const getUserClass = (type) => {
		type = type.toLowerCase()
		return type.replace(/\s/g , "-")
	}


	const formatTimestamp = (ts) => {
		if (ts){
			const options = { month: "long", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" }
			return new Date(ts).toLocaleDateString(undefined, options)
		} else {
			return "N/A"
		}
	}

	useEffect(() => {
		let ignoreNotifications = Cookies.get('ignore-notifications')

		if (ignoreNotifications !== "true"){
			Cookies.set('ignore-notifications', 'false')
			ignoreNotifications = "false"
		}

		if (ignoreNotifications === "false" && popup?.content){
			setOpenEntryPopup(true)
		}

		// eslint-disable-next-line
	}, [popup])

	return (
		<section className="contentWrapper" id="home">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_HOME_TITLE}
			  description={process.env.REACT_APP_SEO_HOME_DESCRIPTION}
			/>

			<section className="primary">
				{
					userClosures?.length && userClosures[0].opposed < userSettings.clear_threshold && userClosures[0].confirmed >= userSettings.confirm_threshold && userSettings.user && userSettings.closures ?

					process.env.REACT_APP_PASS === "vail" || process.env.REACT_APP_PASS === "tunnels" || process.env.REACT_APP_PASS === "glenwood-canyon" ?
					<h2 className={`userReported ${complexStatus.toLowerCase()}`}>
					{complexStatus}
						<Tooltip title={`${userClosures[0].confirmed > 1 ? 'Users' : 'User'} Reported`} placement="top">
							<Badge badgeContent={userClosures[0].confirmed} showZero overlap="circular">
								<Link className="userReported" to="/alerts" state={{ tabIndex: 2}}>*</Link>
							</Badge>
						</Tooltip>
					</h2>
					:
					<h2 className={`userReported ${userClosures[0].report === "road open" ? 'open' : 'closed'}`}>{userClosures[0].report === "road open" ? 'Open' : 'Closed'}
						<Tooltip title={`${userClosures[0].confirmed > 1 ? 'Users' : 'User'} Reported`} placement="top">
							<Badge badgeContent={userClosures[0].confirmed} showZero overlap="circular">
								<Link className="userReported" to="/alerts" state={{ tabIndex: 2}}>*</Link>
							</Badge>
						</Tooltip>
					</h2>
					:
					isComplexStatus ?
						<h2 className={complexStatus.toLowerCase()}>{complexStatus}</h2>
						:
						<h2 className={status?.open ? 'open' : 'closed'}>{status?.open ? 'Open' : 'Closed'}</h2>
				}
					<ul className="conditions">
					{ currentConditions?.length ?
						<>
							{currentConditions.map((condition, i) =>
								<li key={i}>
									{Icons[condition.replace(/\s/g, '')] || Icons['default']}
									<span>{condition}</span>
								</li>
							)}
						</>
						:
						<p>Conditions not available at this time</p>
					}
					{ userConditions?.length && userSettings.user && userSettings.conditions ?
						<>
							{userConditions?.map((condition, i) =>
								condition.confirmed >= userSettings.confirm_threshold  && condition.opposed < userSettings.clear_threshold ?
								<li key={i} className="userReported">
									<div className="wrapper">
										<Badge badgeContent={condition.confirmed} showZero overlap="circular">
											<i onClick={(e) => handleConditionDetails(condition)}>
											{Icons[condition.report.replace(/\s/g , "")] || Icons['default']}
											</i>
										</Badge>
										{auth?.user && !isMobile &&
											<div className="confirmation">
												<div className="down" onClick={(e) => handleOpenOppose(condition)}><BsFillHandThumbsDownFill /></div>
												<div className="up" onClick={(e) => handleOpenConfirm(condition)}><BsFillHandThumbsUpFill /></div>
											</div>
										}
									</div>
									<span>{condition.report}</span>
								</li>
								:
								null
							)}
						</>
						:
						null
					}
						<li className="addReport" onClick={(e) => setOpenReport(true)}>
							<MdAdd />
							<span>Add Report</span>
						</li>
					</ul>
					<div id="key">
						<p><div className="icon cdot"></div>CDOT Reported</p>
						<p><div className="icon user"></div>User Reported - { userSettings.user ? 'Click condition icon for additional information.' : 'Disabled in your settings'}</p>
					</div>
			</section>

			<section className="secondary">
				<section className="flex">
				</section>

				<section className="overview">
					<article>
						<h3>Conditions Summary</h3>
						{conditions?.additional_data ? <p>{conditions?.additional_data}</p> : <p>Conditions summary not available at this time</p>}
					</article>
					<article>
						<ThemeProvider theme={dcColors}>
							<Tabs
								value={tab}
								onChange={handleTabChange}
								orientation={isMobileTabs ? "vertical" : "horizontal"}
						  	>
								<Tab icon={<Badge badgeContent={incidents?.length} color="primary" showZero="true" overlap="circular"></Badge>} iconPosition="start" label="CDOT Incidents" {...a11yProps(0)} />
								<Tab icon={<Badge badgeContent={events?.length} color="primary" showZero="true" overlap="circular"></Badge>} iconPosition="start" label="CDOT Events" {...a11yProps(1)} />
								{ userSettings.user ?
									<Tab icon={<Badge badgeContent={userReports?.length} color="primary" showZero="true" overlap="circular"></Badge>} iconPosition="start" label="User Reports" {...a11yProps(2)} />
									:
									null
								}
							</Tabs>
						</ThemeProvider>

						<TabPanel value={tab} index={0}>
							<>
								<Link to="/alerts" state={{ tabIndex: 0}}><h3>Incidents ({incidents?.length})</h3></Link>
								{ incidents?.length ?
									<ul>
									{incidents?.slice(0,1).map((incident, i) =>
										<li className="incident" key={i}>
											<span className={`icon ${getClass(incident?.incident_type)}`}></span>
											<div className="content">
												<h4>{incident?.incident_type}</h4>
												<div className="description">{incident?.incident_description?.length > 200 ? <><p>{`${incident?.incident_description.substring(0, 200)}...`}</p><Link to="/alerts" state={{ tabIndex: 0}}>Read full incident report</Link></> : <p>{incident?.incident_description}</p>}</div>
											</div>
										</li>
									)}
									</ul>
									:
									<p>No incidents at this time</p>
								}
							</>
						</TabPanel>

						<TabPanel value={tab} index={1}>
							<>
								<Link to="/alerts" state={{ tabIndex: 1}}><h3>Events ({events?.length})</h3></Link>
								{ events?.length ?
									<ul>
									{events?.slice(0,1).map((event, i) =>
										<li className="event" key={i}>
											<span className={`icon ${getClass(event?.planned_type)}`}></span>
											<div className="content">
												<h4>{event?.planned_type}</h4>
												<div className="description">{event?.planned_description?.length > 200 ? <><p>{`${event?.planned_description?.substring(0, 200)}...`}</p><Link to="/alerts" state={{ tabIndex: 1}}>Read full event report</Link></> : <p>{event?.planned_description}</p>}</div>
											</div>
										</li>
									)}
									</ul>
									:
									<p>No events at this time</p>
								}
							</>
						</TabPanel>

						{ userSettings.user ?
							<TabPanel value={tab} index={2}>
								<>
									<Link to="/alerts" state={{ tabIndex: 2}}><h3>User Reports ({userReports.length})</h3></Link>
									<ul className="userAlerts">
										{userReports?.length ?
											<>
												{userReports?.filter(report => report.opposed < userSettings.clear_threshold).slice(0,1).map((report, i) =>
														<li key={i} className="reports">
															<span className={`icon ${getUserClass(report.report)}`}></span>
															<div className="content">
																<h4>{report.report} {report.report_category === 'traffic' && 'Traffic'} {report.report_category === 'construction' && 'Construction'}</h4>
																<p><strong>Reported at:</strong> {formatTimestamp(report.created_at)}</p>
																<Link to="/alerts" state={{ tabIndex: 2}}>Read full user report</Link>
															</div>
														</li>
												)}
											</>
											:
											<p>No user reports at this time</p>
										}

									</ul>
								</>
							</TabPanel>
							:
							null
						}
					</article>

				</section>
			</section>

			<Dialog
				open={openConfirm}
				onClose={handleCloseConfirm}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="confirm"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Confirm User Report`}
				</DialogTitle>
				<DialogContent>
					<>
						<span><strong>User(s) Reported:</strong> {confirmObj.report}</span><br />
						<span><strong>Reported at:</strong> {formatTimestamp(confirmObj.created_at)}</span><br />
						<span><strong>Confirmed Count:</strong> {confirmObj.confirmed}</span><br />
						<span><strong>Last confirmed at:</strong> {formatTimestamp(confirmObj.updated_at)}</span>
					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseConfirm}>Cancel</button>
					<button className="confirm" onClick={handleConfirmConditions}>Confirm</button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openOppose}
				onClose={handleCloseOppose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="oppose"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Clear User Report`}
				</DialogTitle>
				<DialogContent>
					<>
						<p className="clearNote">Is user report '{opposeObj.report}' still present? If it isn't click clear and it will be removed from our report.</p><br />
						<span><strong>User(s) Reported:</strong> {opposeObj.report}</span><br />
						<span><strong>Originally Reported at:</strong> {formatTimestamp(opposeObj.created_at)}</span><br />
						<span><strong>Last updated at:</strong> {formatTimestamp(opposeObj.updated_at)}</span>
					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseOppose}>Cancel</button>
					<button className="confirm" onClick={handleOpposeConditions}>Clear</button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openDetails}
				onClose={handleCloseDetails}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="confirm"
				id="reportDetails"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`User Report Details`}
				</DialogTitle>
				<DialogContent>
					<>
						<span><strong>{detailsObj.confirmed > 1 ? 'Users' : 'User'} Reported:</strong> {detailsObj.report}</span><br />
						<span><strong>Confirmed Count:</strong> {detailsObj.confirmed}</span><br />
						<span><strong>Cleared Count:</strong> {detailsObj.opposed}</span><br />
						<span><strong>Reported at:</strong> {formatTimestamp(detailsObj.created_at)}</span><br />
						<span><strong>Last updated at:</strong> {formatTimestamp(detailsObj.updated_at)}</span><br />
						{!auth?.user &&
							<p className="reportNote">Help keep others informed of latest conditions. Login or signup now to add your own first hand reports.</p>
						}

					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseDetails}>Close</button>
					{ !auth?.user &&
						<button className="confirm" onClick={handleLoginSignup}>Signup/Login</button>
					}
					{ isMobile && auth?.user &&
						<>
							<button className="down" onClick={(e) => handleOpenOppose(detailsObj)}><BsFillHandThumbsDownFill /> <span>Clear</span></button>
							<button className="up" onClick={(e) => handleOpenConfirm(detailsObj)}><BsFillHandThumbsUpFill /> <span>Confirm</span></button>
						</>
					}
				</DialogActions>
			</Dialog>

			<Dialog
				open={openThanks}
				onClose={handleCloseThanks}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="thanks"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Thanks for clearing a old report!`}
				</DialogTitle>
				<DialogContent>
					<>
						<p>Thanks for clearing an old report. If CDOT's data is not providing enough information for current conditions would you like to add your own report now?</p>

					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseThanks}>Close</button>
					<button className="confirm" onClick={handleOpenReport}>Report</button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openEntryPopup}
				onClose={handleCloseEntryPopup}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="updatesPopup"
			  >
				<DialogTitle id="alert-dialog-title">
				  {popup?.title}
				</DialogTitle>
				<DialogContent>
					<>
						<p>{popup?.content}</p>
						<div className="salutaion">
							<p className="right">Save Travels!</p>
							<p className="right">Drive Colorado</p>
						</div>
					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseEntryPopup}>Close</button>
					{popup?.type === "signup" && <button className="confirm" onClick={handleLoginSignup}>Login/Signup</button>}
					<button className="confirm" onClick={handleIgnoreEntryPopup}>Ignore for 1 day</button>
				</DialogActions>
			</Dialog>

			<UserReportPopup openReport={openReport} setOpenReport={setOpenReport} data={data} setData={setData} />

		</section>
	)
}

export default Home