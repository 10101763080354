import { useState, useEffect } from 'react';

const Footer = ({data}) => {
	const ads = data?.ads

	let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
	let closedAlready = window.sessionStorage.getItem("hide-ad")
	const [hideBanner, setHideBanner] = useState(false)

	useEffect(() => {
		setHideBanner(!isIOS || closedAlready)
		// eslint-disable-next-line
	}, [])

	const handleClose = () => {
		setHideBanner(true)
		window.sessionStorage.setItem("hide-ad", true)
	}

	return (
		<footer>
		{ ads?.length ?
			<ul className="ad-wrapper">
			{ads?.map((ad, i) =>
				<li className="ad" key={i}>
						{ad?.ad_name === "weather-on-the-way" &&
						<>
						<div id="banner-container" className={hideBanner ? "hidden" : "show"}>
							<div id="banner-header">
								<button onClick={handleClose} id="bottom-banner-close">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 18 6M6 6l12 12"></path>
									</svg>
								</button>
								<span>Sponsored by:</span>
							</div>
							<div id="bottom-banner">
								<div id="content">
									<img src="https://weatherontheway.app/us-interstate-weather/assets/logo-CLpybfBG.png" loading="lazy" alt="" />
									<div id="col">
										<div>
											<div id="app-name">Weather on the Way</div>
											<div id="app-subtitle">Road Conditions, Trip Planner</div>
										</div>
										<div id="app-stars">★★★★★ 4.7</div>
									</div>
								</div>
								<a id="bottom-banner-get" href="https://apps.apple.com/app/apple-store/id1471394318?pt=122239314&amp;ct=bottom-banner-passes&amp;mt=8" target="_blank" className="bg-orange-400 rounded-full px-5 py-2 font-semibold flex-shrink-0" rel="noreferrer">Get App</a>
								<link rel="prefetch" href="https://apps.apple.com/app/apple-store/id1471394318?pt=122239314&amp;ct=bottom-banner-passes&amp;mt=8" />
							</div>
						</div>
						</>
						}
				</li>
			)}
			</ul>
			:
			""
		}
			<p>Powered by <a href="https://drive-colorado.com" target="_blank">Drive Colorado</a> &copy;{new Date().getFullYear()}</p>
		</footer>
	)
}

export default Footer