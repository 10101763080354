import { SuperSEO } from "react-super-seo";
import { useOutletContext, useLocation, useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from 'react';
import TabPanel from '../components/TabPanel'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import { Parser } from 'html-to-react'
import { AreaChart, Area, LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, LabelList } from 'recharts';
import DayChart from "../components/DayChart";
import AvyScale from "../components/AvyScale";
import { MdInfo } from "react-icons/md";
import WeatherIcons from "../components/WeatherIcons";
import { LiaLongArrowAltDownSolid } from "react-icons/lia";
import { LiaLongArrowAltUpSolid } from "react-icons/lia";
import { useMediaQuery } from 'react-responsive'


const dcColors = createTheme({
  palette: {
	primary: {
	  light: '#007e9b',
	  main: '#007e9b',
	  contrastText: '#007e9b',
	},
	contrastThreshold: 3,
	tonalOffset: 0,
  },
});

const About = () => {

	const location = useLocation()
	const { tabIndex } = location?.state || 0

	const [tab, setTab] = useState(tabIndex || 0)

	const [data, setData] = useOutletContext()
	const [forecast, setForecast] = useState()
	const [snow, setSnow] = useState()
	const [avalanche, setAvalanche] = useState()
	const [avyDanger, setAvyDanger] = useState([])
	const [avySummary, setAvySummary] = useState([])

	const [days, setDays] = useState([])

	useEffect(() => {
		setForecast(data.forecast)
		setSnow(data.snow_reports)
		setAvalanche(data.avalanche_reports)
		setAvyDanger(data.avalanche_reports?.dangerRatings ? JSON.parse(data.avalanche_reports?.dangerRatings) : null)
		setAvySummary(data.avalanche_reports?.avalancheSummary ? JSON.parse(data.avalanche_reports?.avalancheSummary) : null)

		// Parse daily forecast
		days.push(JSON.parse(data.forecast.daily_0))
		days.push(JSON.parse(data.forecast.daily_1))
		days.push(JSON.parse(data.forecast.daily_2))
		days.push(JSON.parse(data.forecast.daily_3))
		days.push(JSON.parse(data.forecast.daily_4))
		days.push(JSON.parse(data.forecast.daily_5))
		days.push(JSON.parse(data.forecast.daily_6))
		days.push(JSON.parse(data.forecast.daily_7))

	}, [data])

	const isMobileTabs = useMediaQuery({
		query: '(max-width: 600px)'
	})

	const formatTimestamp = (ts) => {
		if (ts){
			const options = { month: "long", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" }
			return new Date(ts).toLocaleDateString(undefined, options)
		} else {
			return "N/A"
		}
	}

	function a11yProps(index) {
	  return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	  };
	}

	const handleTabChange = async (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	}


	return (
		<section className="contentWrapper" id="weather">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_WEATHER_TITLE}
			  description={process.env.REACT_APP_SEO_WEATHER_DESCRIPTION}
			/>

			<section className="primary">

				<article>

					<ThemeProvider theme={dcColors}>
						<Tabs
							value={tab}
							onChange={handleTabChange}
							orientation={isMobileTabs ? "vertical" : "horizontal"}
					  	>
							<Tab label="Weather" {...a11yProps(0)} />
							<Tab label="Snow Report" {...a11yProps(1)} />
						</Tabs>
							{/* <Tab label="Avalanche Conditions" {...a11yProps(2)} /> */}
					</ThemeProvider>

					<TabPanel value={tab} index={0}>
						<>
							<h2>Weather</h2>
							<em>Weather Updates on the hour.<br /> Last updated: {formatTimestamp(forecast?.updated_at)}</em>
							{forecast ?
								<div id="currentWeather">
									<div id="current">
										<h3>Current Conditions</h3>
										<div>
											<WeatherIcons id={forecast.current_weather_icon}/>
											<div>
												<p id="temp">{forecast.current_temp}</p>
												<p id="feels_like">{forecast.current_feels_like}</p>
											</div>
											<div>
												<p id="wind">{forecast.current_wind_speed}</p>
												<p id="gusts">{forecast.curent_wind_gust}</p>
											</div>
											{/* <p id="description">{forecast.current_weather_description}</p>
											<p id="main">{forecast.current_weather_main}</p> */}
										</div>
									</div>
									<div id="forecast">
										{ days?.slice(0,8).map((day, index) =>
											<div className={`day ${index == 0 ? 'today' : ''}`} key={index}>
												{ index == 0 ?
													<div id='todayContainer'>
														<h3>What to expect today</h3>
														<div id='todayWrapper'>
															<div>
																<WeatherIcons id={day?.weather_icon} />
																<div>
																	<p>{day?.summary}</p>
																	<p className='temp min'><LiaLongArrowAltDownSolid />{Math.round(day?.temp_min)}</p>
																	<p className='temp max'><LiaLongArrowAltUpSolid />{Math.round(day?.temp_max)}</p>
																	<p className='wind'>{Math.round(day?.wind_speed)}</p>
																	<p className='gusts'>{Math.round(day?.wind_gust)}</p>
																</div>
															</div>
															<DayChart day={day} />
														</div>
													</div>
													:
													<>
														<h3>{day?.datetime}</h3>
														<WeatherIcons id={day?.weather_icon} />
														<p>{day?.weather_description}</p>
														<p className='temp min'><LiaLongArrowAltDownSolid />{Math.round(day?.temp_min)}</p>
														<p className='temp max'><LiaLongArrowAltUpSolid />{Math.round(day?.temp_max)}</p>
													</>
												}
											</div>
										)}


									</div>
								</div>
								:
								<p>Forecast not available at this time</p>
							}
						</>
					</TabPanel>

					<TabPanel value={tab} index={1}>
						<>
							{snow?.length ?
								<div id="snow">
									<h3>Snow Report (last 10 days)</h3>
									<em>New snow totals are estimated from Snotel Stations using snowpack and water data.<br />
									We get new data daily at 5am. Snotel stations only update once a day, so unfortunetly we are unable to get todays data.</em>

									<div>
										<AreaChart
									  	width={500}
									  	height={300}
									  	data={snow}
									  	margin={{
										  	top: 10,
										  	right: 30,
										  	left: 0,
										  	bottom: 0,
											}}
										>
									  	<CartesianGrid strokeDasharray="3 3" />
									  	<XAxis dataKey="short_date" />
									  	<YAxis />
									  	<Tooltip />
									  	<Legend />
									  	<Area dataKey="snow_depth" type="monotone" stackId="a" fill="#007e9b" name="Snow Pack Depth" unit=" inches" />
										</AreaChart>

										<BarChart
									  	width={500}
									  	height={300}
									  	data={snow}
									  	margin={{
											top: 20,
											right: 30,
											left: 20,
											bottom: 5,
									  	}}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="short_date" />
											<YAxis />
											<Tooltip />
											<Legend />
											<Bar dataKey="estimated_new_snow" name="New Snow" stackId="a" fill="#007e9b" unit=" inches" />
										</BarChart>
									</div>
								</div>
								:
								<div id="snow">
								<h3>Snow Report (last 10 days)</h3>
								<em>Snow report not available at this time.</em>
								</div>
							}
						</>
					</TabPanel>

					<TabPanel value={tab} index={2}>
						<>
							{avalanche &&
								<div id="avalanche">
									<h3>Avalanche Report</h3>
									<div className={`avyDanger ${avyDanger?.below_treeline}`}>
										Below Treeline
										<span className='avyStatus'>
											({avyDanger?.below_treeline}) <MdInfo />
											<div className="avyDescription">
												<AvyScale area="Below Treeline" status={avyDanger?.below_treeline} />
											</div>
										</span>
									</div>

									<div className={`avyDanger ${avyDanger?.near_treeline}`}>
										Near Treeline
										<span className='avyStatus'>
											 ({avyDanger?.near_treeline}) <MdInfo />
											<div className="avyDescription">
												<AvyScale area="Near Treeline" status={avyDanger?.near_treeline} />
											</div>
										</span>
									</div>

									<div className={`avyDanger ${avyDanger?.above_treeline}`}>
										Above Treeline
										<span className='avyStatus'>
											({avyDanger?.above_treeline}) <MdInfo />
											<div className="avyDescription">
												<AvyScale area="Near Treeline" status={avyDanger?.above_treeline} />
											</div>
										</span>
									</div>

									{Parser().parse(avySummary.content)}
									<p>Issued: {formatTimestamp(avalanche.issueDateTime)}</p>
									<p>Expires: {formatTimestamp(avalanche.expiryDateTime)}</p>
									<p>Reported by {avalanche.forecaster}</p>
									<p>View the full CAIC report and more by clicking the logo</p>
									<a target="_blank" href="https://avalanche.state.co.us/" alt="CAIC">CAIC</a>
								</div>
							}
						</>
					</TabPanel>

				</article>
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default About