import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import { MdReportProblem } from 'react-icons/md';
import axios from '../api/axios';
import { ToastContainer, toast } from 'react-toastify';
import { SuperSEO } from "react-super-seo";

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const MESSAGE_REGEX = /^[a-zA-Z0-9!%&#@,./'? ]{2,500}$/;
const NAME_REGEX = /^[a-zA-Z- ]{2,50}$/;
const URL_REGEX = /^[a-zA-Z- ]{2,50}$/;

const Support = () => {

	const emailRef = useRef();
	const errRef = useRef();
	const messageRef = useRef();
	const siteURLRef = useRef();
	const routeRef = useRef();
	const firstNameRef = useRef();
	const lastNameRef = useRef();

	const [sending, setSending] = useState(false)
	const [sent, setSent] = useState(false)

	const [firstName, setFirstName] = useState('');
	const [validFirstName, setValidFirstName] = useState(false);
	const [firstNameFocus, setFirstNameFocus] = useState(false);

	const [lastName, setLastName] = useState('');
	const [validLastName, setValidLastName] = useState(false);
	const [lastNameFocus, setLastNameFocus] = useState(false);

	const [message, setMessage] = useState('');
	const [validMessage, setValidMessage] = useState(false);
	const [messageFocus, setMessageFocus] = useState(false);

	const [email, setEmail] = useState('');
	const [validEmail, setValidEmail] = useState(false);
	const [emailFocus, setEmailFocus] = useState(false);

	const [siteURL, setSiteURL] = useState('');
	const [validSiteURL, setValidSiteURL] = useState(false);
	const [siteURLFocus, setSiteURLFocus] = useState(false);

	const [type, setType] = useState('general'); //sets default support type
	const [validType, setValidType] = useState(false);
	const [typeFocus, setTypeFocus] = useState(false);

	const [route, setRoute] = useState('Berthoud Pass'); //sets default support type
	const [validRoute, setValidRoute] = useState(false);
	const [routeFocus, setRouteFocus] = useState(false);

	const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);

	/* Check email */
	useEffect(() => {
		const result = EMAIL_REGEX.test(email);
		setValidEmail(result);
	}, [email])

	/* Check message */
	useEffect(() => {
		const result = MESSAGE_REGEX.test(message);
		setValidMessage(result);
	}, [message])

	/* Check First Name */
	useEffect(() => {
		const result = NAME_REGEX.test(firstName);
		setValidFirstName(result);
	}, [firstName])

	/* Check Last Name */
	useEffect(() => {
		const result = NAME_REGEX.test(lastName);
		setValidLastName(result);
	}, [lastName])

	/* Check Site URL */
	useEffect(() => {
		const result = URL_REGEX.test(siteURL);
		setValidSiteURL(result);
	}, [siteURL])


	const submitForm = async (e) => {
		e.preventDefault()

		if (type == 'general'){
			// Double check form for hijacking
			const v1 = EMAIL_REGEX.test(email);
			const v2 = MESSAGE_REGEX.test(message);
			const v3 = NAME_REGEX.test(firstName);
			const v4 = NAME_REGEX.test(lastName);

			if (!v1 || !v2 || !v3 || !v4){
				setErrMsg("Invalid Entry");
				return;
			}
		} else if (type == 'widget') {
			// Double check form for hijacking
			const v1 = EMAIL_REGEX.test(email);
			const v2 = MESSAGE_REGEX.test(message);
			const v3 = NAME_REGEX.test(firstName);
			const v4 = NAME_REGEX.test(lastName);
			const v5 = URL_REGEX.test(siteURL);

			if (!v1 || !v2 || !v3 || !v4 || !v5){
				setErrMsg("Invalid Entry");
				return;
			}

		} else {
			setErrMsg("Invalid Entry");
			return;
		}

		setSending(true)
		const dismiss = () =>  toast.dismiss(submitFormToast.current);
		const submitFormToast = toast.loading("Submitting Form");
		if (type == 'general'){
			try {
				const response = await axios.post('send-support-notification',
					JSON.stringify({first_name: firstName, last_name: lastName, email: email, message: message, pass: process.env.REACT_APP_PASS_NAME}),
					{
						headers: {
							'Content-Type': 'application/json',
							'Accept': 'application/json'
						},
						withCredentials: true
					}
				);
				toast.update(submitFormToast, { render: 'Form Sent', type: 'success', isLoading: false, autoClose: 5000});
				setSending(false)
				setSent(true)

			} catch (err) {
				if (!err?.response){
					toast.update(submitFormToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				} else {
					toast.update(submitFormToast, { render: 'Form Send Failed', type: 'error', isLoading: false, autoClose: 5000});
				}
				setSending(false)

			}
		}
	}

	return (
		<section className="contentWrapper" id="support">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_SUPPORT_TITLE}
			  description={process.env.REACT_APP_SEO_SUPPORT_DESCRIPTION}
			/>

			<section className="primary">
				<h2>Support</h2>

				{ !sent ?
					<form onSubmit={submitForm}>

						{/* Support  Type */}
						<label htmlFor="type">Support Type:</label>
						<div className="inputWrapper">
							<select
								id="type"
								onChange={(e) => setType(e.target.value)}
								value={type}
								required
								onFocus={() => setTypeFocus(true)}
								onBlur={() => setTypeFocus(false)}
							>
								<option value="general">General Support/Feedback</option>
								<option value="widget">Request Widget Access</option>
								<option value="error">Conditions/Closures</option>
								<option value="cameras">Cameras</option>
								<option value="logout">I keep getting logged out!</option>

							</select>
						</div>

						{/* General Support Message */}
						{ type == 'general' ?
							<>
								<p><span>Our general support form is a great spot for you to get answers to questions, report site issues, request features or give us praise (We LOVE that!)</span></p>
								<p className="supportNotice"><FaExclamation /><span>Please do not ask us when the pass will be open or if will be closed on a certain day. Our crystal ball is currently in the repair shop. Hence, we do not know this information.  Questions along these lines will be ignored.</span></p>

								{/* First Name */}
								<label htmlFor="fname">First Name: <span className="required">*</span></label>
								<div className="inputWrapper">
									<span className={validFirstName ? "valid" : "hide"}><FaCheck /></span>
									<span className={validFirstName || !firstName ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="fname"
										className={validFirstName || !firstName ? null : "error"}
										ref={firstNameRef}
										autoComplete="off"
										onChange={(e) => setFirstName(e.target.value)}
										required
										aria-invalid={validFirstName ? "false" : "true"}
										aria-describedby="first-name-note"
										onFocus={() => setFirstNameFocus(true)}
										onBlur={() => setFirstNameFocus(false)}
									/>
								</div>
								<p id="first-name-note" className={firstNameFocus && firstName && !validFirstName ? "instructions" : "offscreen"}>
									Invalid first name.
								</p>

								{/* Last Name */}
								<label htmlFor="lname">Last Name: <span className="required">*</span></label>
								<div className="inputWrapper">
									<span className={validLastName ? "valid" : "hide"}><FaCheck /></span>
									<span className={validLastName || !lastName ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="lname"
										className={validLastName || !lastName ? null : "error"}
										ref={lastNameRef}
										autoComplete="off"
										onChange={(e) => setLastName(e.target.value)}
										required
										aria-invalid={validFirstName ? "false" : "true"}
										aria-describedby="first-name-note"
										onFocus={() => setLastNameFocus(true)}
										onBlur={() => setLastNameFocus(false)}
									/>
								</div>
								<p id="first-name-note" className={lastNameFocus && lastName && !validLastName ? "instructions" : "offscreen"}>
									Invalid last name.
								</p>

								{/* Email */}
								<label htmlFor="email">Email: <span className="required">*</span></label>
								<div className="inputWrapper">
									<span className={validEmail ? "valid" : "hide"}><FaCheck /></span>
									<span className={validEmail || !email ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="email"
										id="email"
										className={validEmail || !email ? null : "error"}
										ref={emailRef}
										autoComplete="off"
										onChange={(e) => setEmail(e.target.value)}
										required
										aria-invalid={validEmail ? "false" : "true"}
										aria-describedby="email-note"
										onFocus={() => setEmailFocus(true)}
										onBlur={() => setEmailFocus(false)}
									/>
								</div>
								<p id="email-note" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
									Invalid email address.
								</p>

								{/* Message */}
								<label htmlFor="message">Message: <span className="required">*</span></label>
								<div className="inputWrapper">
									<span className={validMessage ? "valid" : "hide"}><FaCheck /></span>
									<span className={validMessage || !message ? "hide" : "invalid"}><FaExclamation /></span>
									<textarea
										ref={messageRef}
										onChange={(e) => setMessage(e.target.value)}
										name="message"
										id="message"
										rows="5"
										value={message}
									>
									</textarea>
									<p id="message-note" className={messageFocus && message && !validMessage ? "instructions" : "offscreen"}>
										Invalid message. Between 1-500 characters letters, numbers and allowed special characters (!%&#@,./'?)
									</p>
								</div>

								<button className={sending ? 'sending' : null} disabled={ !validEmail || !validMessage || !validFirstName || !validLastName ? true :  false}>{sending ? 'Sending' : 'Submit' }</button>

							</>
							:
							null
						}

						{/* Request Widget Access */}
						{ type == 'widget' ?
							<>
								<p><span>Display our open/closed status on your page with our widget. Fill out the request form below and we will get back to you.</span></p>
								{/* First Name */}
								<label htmlFor="fname">First Name: <span className="required">*</span></label>
								<div className="inputWrapper">
									<span className={validFirstName ? "valid" : "hide"}><FaCheck /></span>
									<span className={validFirstName || !firstName ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="fname"
										className={validFirstName || !firstName ? null : "error"}
										ref={firstNameRef}
										autoComplete="off"
										onChange={(e) => setFirstName(e.target.value)}
										required
										aria-invalid={validFirstName ? "false" : "true"}
										aria-describedby="first-name-note"
										onFocus={() => setFirstNameFocus(true)}
										onBlur={() => setFirstNameFocus(false)}
									/>
								</div>
								<p id="first-name-note" className={firstNameFocus && firstName && !validFirstName ? "instructions" : "offscreen"}>
									Invalid first name.
								</p>

								{/* Last Name */}
								<label htmlFor="lname">Last Name: <span className="required">*</span></label>
								<div className="inputWrapper">
									<span className={validLastName ? "valid" : "hide"}><FaCheck /></span>
									<span className={validLastName || !lastName ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="lname"
										className={validLastName || !lastName ? null : "error"}
										ref={lastNameRef}
										autoComplete="off"
										onChange={(e) => setLastName(e.target.value)}
										required
										aria-invalid={validLastName ? "false" : "true"}
										aria-describedby="first-name-note"
										onFocus={() => setLastNameFocus(true)}
										onBlur={() => setLastNameFocus(false)}
									/>
								</div>
								<p id="first-name-note" className={lastNameFocus && lastName && !validLastName ? "instructions" : "offscreen"}>
									Invalid last name.
								</p>

								{/* Email */}
								<label htmlFor="email">Email: <span className="required">*</span></label>
								<div className="inputWrapper">
									<span className={validEmail ? "valid" : "hide"}><FaCheck /></span>
									<span className={validEmail || !email ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="email"
										id="email"
										className={validEmail || !email ? null : "error"}
										ref={emailRef}
										autoComplete="off"
										onChange={(e) => setEmail(e.target.value)}
										required
										aria-invalid={validEmail ? "false" : "true"}
										aria-describedby="email-note"
										onFocus={() => setEmailFocus(true)}
										onBlur={() => setEmailFocus(false)}
									/>
								</div>
								<p id="email-note" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
									Invalid email address.
								</p>

								{/* Site URL */}
								<label htmlFor="siteURL">Site URL: <span className="required">*</span></label>
								<div className="inputWrapper">
									<span className={validSiteURL ? "valid" : "hide"}><FaCheck /></span>
									<span className={validSiteURL || !siteURL ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="siteURL"
										className={validSiteURL || !siteURL ? null : "error"}
										ref={siteURLRef}
										autoComplete="off"
										onChange={(e) => setSiteURL(e.target.value)}
										required
										aria-invalid={validSiteURL ? "false" : "true"}
										aria-describedby="site-url-note"
										onFocus={() => setSiteURLFocus(true)}
										onBlur={() => setSiteURLFocus(false)}
									/>
								</div>
								<p id="site-url-note" className={siteURLFocus && siteURL && !validSiteURL ? "instructions" : "offscreen"}>
									Invalid site URL.
								</p>

								<label htmlFor="route">Which route do you want to display:</label>
								<div className="inputWrapper">
									<select
										id="route"
										onChange={(e) => setRoute(e.target.value)}
										value={route}
										required
										onFocus={() => setRouteFocus(true)}
										onBlur={() => setRouteFocus(false)}
									>
										<option value="Berthoud Pass">Berthoud Pass</option>
										<option value="Cameron Pass">Cameron Pass</option>
										<option value="Coal Bank Pass">Coal Bank Pass</option>
										<option value="Fremont Pass">Fremont Pass</option>
										<option value="Glenwood Canyon">Glenwood Canyon</option>
										<option value="Hoosier Pass">Hoosier Pass</option>
										<option value="Kenosha Pass">Kenosha Pass</option>
										<option value="Lizard Head Pass">Lizard Head Pass</option>
										<option value="Loveland Pass">Loveland Pass</option>
										<option value="Molas Pass">Molas Pass</option>
										<option value="Monarch Pass">Monarch Pass</option>
										<option value="Rabbit Ears Pass">Rabbit Ears Pass</option>
										<option value="Red Mountain Pass">Red Mountain Pass</option>
										<option value="Tennessee Pass">Tennessee Pass</option>
										<option value="Tunnels">Tunnels (I-70 | Eisenhower/Johnson Tunnels)</option>
										<option value="Vail Pass">Vail Pass</option>
										<option value="Wolf Creek Pass">Wolf Creek Pass</option>

									</select>
								</div>

								{/* Message */}
								<label htmlFor="message">Any additional information we should know: <span className="required">*</span></label>
								<div className="inputWrapper">
									<span className={validMessage ? "valid" : "hide"}><FaCheck /></span>
									<span className={validMessage || !message ? "hide" : "invalid"}><FaExclamation /></span>
									<textarea
										ref={messageRef}
										onChange={(e) => setMessage(e.target.value)}
										name="message"
										id="message"
										rows="5"
										value={message}
									>
									</textarea>
									<p id="message-note" className={messageFocus && message && !validMessage ? "instructions" : "offscreen"}>
										Invalid message. Between 1-500 characters letters, numbers and allowed special characters (!%&#@,./'?)
									</p>
								</div>

								<button className={sending ? 'sending' : null} disabled={ !validEmail || !validMessage || !validFirstName || !validLastName || !validRoute || !validSiteURL ? true :  false}>{sending ? 'Sending' : 'Submit' }</button>
							</>
							:
							null
						}
						{ type == "error" ?
							<>
								<p>Conditions and closures aren't always 100% correct from CDOT, which is why we added user reports. If things aren't correct or up-to-date you can now submit your own first-hand reports. Sign up for a free account and start submitting your own conditions!</p>
								<Link to="/login">Signup/Login</Link>
							</>
							:
							null
						}
						{ type == "cameras" ?
							<>
								<p>Unfortunately cameras are one thing we have absoluntely no control over. If cameras are down or out of date, we can't do anything about it. We have to wait for CDOT or whoever owns and maintains the camera to fix them.</p>
							</>
							:
							null
						}
						{ type == "logout" ?
							<>
								<p>We are still working out a few bugs like when you refresh the page and it logs you out. Try using the refresh button by the pass name. This will refresh the data without logging you out.</p>
							</>
							:
							null
						}
					</form>
					:
					<p>Thank you for your message. We will get back to you ASAP.</p>
				}
			</section>

			<section className="secondary">
			</section>

		</section>
	)
}

export default Support