import Navigation from './Navigation'
import PassHeading from './PassHeading'
import SecondaryNavigation from './SecondaryNavigation'

const Header = ({ loading, notifications, refreshData, data }) => {
	return (
		<header>
			<main>
				<PassHeading refreshData={refreshData} loading={loading} />
				{!loading && <Navigation />}
			</main>
			{!loading && <SecondaryNavigation notifications={notifications} data={data} />}
		</header>
	)
}

export default Header